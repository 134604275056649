import React, { useState, useRef } from "react"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import font from "../../fonts/fonts.module.scss"
import CustomAxios from "../../utils/axios"
import { ConsentGtag } from "../../utils/consentGtag"

import { Buttons } from "../buttons"

const Form = ({ data, btn }) => {
  const [response, setResponse] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const name = useRef(null);
  const phone = useRef(null);
  const address = useRef(null);
  const email = useRef(null);
  const message = useRef(null);

  var checker = [];
  function checkValues() {
    checker = [];
    let checkerTemp = [];

    name.current.style.borderBottom = "1px solid #fff";
    email.current.style.borderBottom = "1px solid #fff";
    phone.current.style.borderBottom = "1px solid #fff";
    address.current.style.borderBottom = "1px solid #fff";
    message.current.style.borderBottom = "1px solid #fff";
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!name.current.value) {
      checkerTemp.push("nome");
      name.current.style.borderBottom = "1px solid red";
    }
    if (!email.current.value) {
      checkerTemp.push("email");
      email.current.style.borderBottom = "1px solid red";
    }
    if (!email.current.value.match(validRegex)) {
      checkerTemp.push("email inválido");
      email.current.style.borderBottom = "1px solid red";
    }
    if (!phone.current.value) {
      checkerTemp.push("telefone");
      phone.current.style.borderBottom = "1px solid red";
    }
    if (!address.current.value) {
      checkerTemp.push("endereço");
      address.current.style.borderBottom = "1px solid red";
    }
    if (!message.current.value) {
      checkerTemp.push("mensagem");
      message.current.style.borderBottom = "1px solid red";
    }
    checker = checkerTemp;
  }

  function doSubmit() {
    checkValues();
    if (checker.length < 1) {

      ConsentGtag()

      var formData = new FormData()
      formData.append("name", name.current.value)
      formData.append("email", email.current.value)
      formData.append("phone", phone.current.value)
      formData.append("address", address.current.value)
      formData.append("message", message.current.value)
      formData.append("contact", true)

      CustomAxios("https://hcsperformance.pt/form-contact.php", formData, "ENVIADO").then((res) => {
        if (res !== "")
          setResponse("Faltam os seguintes campos: " + checker + ".");
        else
          success("OBRIGADO PELA SUA MENSAGEM")
      });
      //CustomAxios("/form-contact.php", formData, "OBRIGADO PELA SUA MENSAGEM").then((res) => success(res));
      //CustomAxios("https://invisual.pt/teste-form/form-contact.php", formData, "ENVIADO").then((res) => {
    }
    else {
      setResponse("Faltam os seguintes campos: " + checker + ".");
    }
  }

  const success = (res) => {
    setResponse(res)
    setFormSuccess(true)
    if (typeof window !== "undefined") {
      window.gtag("event", 'form_contacto', {
        form: "Formulário de Contacto"
      })
    }
  }

  return (
    <StyledForm id="form">
      <h1 className={font.oEL + " title"}>{data.title}</h1>
      <p className={font.oR + " subtitle"}>{data.subtitle}</p>

      {response === "OBRIGADO PELA SUA MENSAGEM" ?
        <div className={formSuccess ? "form-grid  form-success" : "form-grid"}>
          <h4 className={font.oR + " response"}>{response}</h4>
        </div>
        :
        <form className="form-grid " netlify >
          <div className="form-section">
            <p className={font.oL + " big formTitle"}>{data.name}</p>
            <input type="text" ref={name} className={font.oL} />
          </div>

          <div className="form-section">
            <p className={font.oL + " big formTitle"}>{data.phone}</p>
            <input type="text" ref={phone} className={font.oL} />
          </div>

          <div className="form-section">
            <p className={font.oL + " big formTitle"}>{data.address}</p>
            <input type="text" ref={address} className={font.oL} />
          </div>

          <div className="form-section">
            <p className={font.oL + " big formTitle"}>{data.email}</p>
            <input type="text" ref={email} className={font.oL} id="email" />
          </div>

          <div className="form-section">
            <p className={font.oL + " big formTitle"}>{data.message}</p>
            <input type="text" ref={message} className={font.oL} />
          </div>

          <div className="form-section">
            <p className={font.oL + " big formTitle"} style={{ height: "1em" }} />
            <div className={font.oEL + " form-section div-btn "} >
              <Buttons text={btn.btnText} actionFunction={doSubmit}
                btnClass={btn.btnClass} />
            </div>
          </div>
        </form>
      }
      {response !== "OBRIGADO PELA SUA MENSAGEM" && <h4 className={font.oR + " response"}>{response}</h4>}
    </StyledForm>
  )
}


export default Form

const StyledForm = styled(Div100vh)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: all 650ms ease-out;
  .title{
    margin-bottom: 0.5em;
  }
  .div-btn{
    text-transform: uppercase;
    position: relative;
    width: 12vw;
    float: left;
  }
  .form-grid{
    display: grid;
    width: 65%;
    margin: 5em auto;
    grid-template-columns: 3fr 2fr;
    gap: 4em 5em;
    .form-section{
      text-align: left;
      input{
        color: #fff;
        margin-top: 1em;
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: 2px solid #fff;
      }
      .form-send-btn{
        align-self: center;
        display: block;
        margin: auto;
        height: 2.5em;
        text-transform: uppercase;
        img{
          height: 100%;
        }
      }
    }
  }
  .response{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    grid-column-start: 1;
    grid-column-end: 3;
  }
`