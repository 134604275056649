import React from "react";
import { ConsentGtag } from "./consentGtag";

export const handleClick = (url) => {
    var type
    if(url.includes('tel:+351919671850') || url.includes('tel:+351 919 671 850')){
        type = 'Geral'
    }
    else if(url.includes('tel:+351234035862') || url.includes('tel:+351 234 035 862')){
        type = 'Assistência'
    }
    else if(url.includes('tel:+351912091667') || url.includes('tel:+351 912 091 667')){
        type = 'Stand'
    }

    if (typeof window !== "undefined") {
        ConsentGtag()
        window.gtag('event', 'chamadas', {
            departamento: type,
        })

        window.open(url, '_self', 'noreferrer')
    }
}