import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import Intro from "../components/intro/intro"
import IntroMobile from "../components/intro/introMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import Slider from "../components/slider/sliderContactos"
import SliderMobile from "../components/slider/sliderContactosMobile"
import Form from "../components/forms/contactForm"
import FormMobile from "../components/forms/contactFormMobile"


const ContactosPage = ({ data }) => (
  <>
    <SEO title="Contactos" image={data.contactosJson.intro.background.childImageSharp.fluid.src}/>
    <Layout>
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />
          <IntroMobile data={data.contactosJson.introMobile} />
          <SliderMobile data={data.contactosJson.info} />
          <FormMobile data={data.contactosJson.messageForm} btn={data.contactosJson.btn} />
          <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />

          <Intro data={data.contactosJson.intro} tall />
          <Slider data={data.contactosJson.info} />
          <Form data={data.contactosJson.messageForm} btn={data.contactosJson.btn} />
          <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      )}
    </Layout>
  </>
)

export default ContactosPage

export const Json = graphql`
  query contactos {
    contactosJson {
      btn{
        btnText
        btnClass
        btnLink
      }
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subtitle
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subtitle
      }
      info {
        address
        addressTxt
        phone
        phoneTxt
        phoneTxtLink
        email
        emailTxt
        emailTxtLink
        hours
        hoursTxt
      }
      messageForm {
        title
        subtitle
        name
        phone
        address
        email
        message
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
