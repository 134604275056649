import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import Div100vh from 'react-div-100vh'
import font from "../../fonts/fonts.module.scss"
import marcar from "../../images/home/marcar-assistencia.svg"
import assistbmw from "../../images/home/assistencia-bmw.svg"
import { Buttons } from '../buttons'

const Intro = ({data, tall, small, btn, bmw, introBtns, text, btnClass, actionFunction}) => {
  
  return(
    <StyledIntro className="intro" fluid={data.background.childImageSharp.fluid} backgroundColor={`#000000`}>
      <StyledDiv100vh tall={tall ? +tall : undefined}>
      {bmw ? 
          <h1 className={font.hB + " intro-title intro-tall"}>
            {data.title.map((data, i)=>(<span key={"introTile" + i} className={i===0 ? font.oL : undefined}>{data}<br/></span>))}
          </h1>
          :
          <h1 className={tall ? font.oL + " intro-title intro-tall" : font.oL + " intro-title"}>
            {data.title.map((data, i)=>(<span key={"introTile" + i} className={small ? i>0 ? font.oM + " small-title" : undefined : undefined}>{data}<br/></span>))}
          </h1>
        }
        {data.subtitle && 
          data.subtitle.length>1 ? 
            <p className={`${font.oR} intro-subtitle`}>
              {data.subtitle.map((data, i)=>(
                <span className={`${font.oR} intro-subtitle`} key={"subtitle" + i}>{data}<br/></span>
              ))}
            </p> 
          : 
          <p className={`${font.oR} intro-subtitle`}>{data.subtitle}</p>
        }
        {data.text && <p className={`${font.oL} intro-text`}>{data.text}</p>}
        {introBtns &&
          <div className="intro-mobile-btns">
            <Link to="/assistencia-certificada" className="btn-intro"><img src={assistbmw} alt="Assistência BMW"/></Link>
            <Link to="/marcacao-assistencia" className="btn-intro"><img src={marcar} alt="Marcação de Assistência"/></Link>
          </div>
        }
        {btn && <div className={font.oEL + ' p-relative container-btn'} >
          <Buttons text={text} actionFunction={actionFunction} btnClass={btnClass} />
        </div>}
      </StyledDiv100vh>
    </StyledIntro>
  )
}


export default Intro

const StyledIntro = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .p-relative{
    position: relative;
  }
  .container-btn{
    width: 35vw;
    margin: 2vh auto 0;
    .intro-text{
      width: 100% !important;
    }
  }
`

const StyledDiv100vh = styled(Div100vh)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  .intro-title{
    margin: 18vh 6vw 0;
  }
  .intro-tall{
    margin: 55vh 6vw 0;
  }
  .intro-subtitle{
    margin: 2vw 6vw 0;
  }
  .intro-text{
    width: 80%;
    margin: 1em auto;
  }
  .btn{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    img{
      height: 2.5em;
    }
  }
  .intro-mobile-btns{
    position: absolute;
    bottom: calc(80px + 15vw);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 80%;
    justify-content: space-around;
    a{
      img{
        height: 2.5em;
      }
    }
  }
`