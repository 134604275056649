import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import email from "../../images/contactos/Email.svg"
import horario from "../../images/contactos/Horario.svg"
import local from "../../images/contactos/Local.svg"
import phone from "../../images/contactos/Phone.svg"

import { handleClick } from "../../utils/handleClick"

const Slider = ({data}) => {
  
  return(
    <StyledSlider>
      <div className="info-detes">
        <div className="flex-section">
          <p><img src={local} alt={data.address}/></p>
          {data.addressTxt.map((data, i)=>(
            <p className={font.oL} key={"address-line-" + i}>{data}<br/></p>
          ))}
        </div>
        <div className="flex-section">
          <div id='phones' />
          <p><img src={phone} alt={data.phone}/></p>
          {data.phoneTxt.map((dataPhone, i)=>(
            <a onClick={()=> handleClick("tel:" + data.phoneTxtLink[i])}  key={"phone-line-" + i}><p className={font.oL}>{dataPhone}<br/></p></a>
          ))}
        </div>
        <div className="flex-section">
          <p><img src={email} alt={data.email}/></p>
          {data.emailTxt.map((dataEmail, i)=>(
            <a href={"mailto:" + data.emailTxtLink[i]} key={"email-line-" + i}><p className={font.oL}>{dataEmail}<br/></p></a>
          ))}
        </div>
        <div className="flex-section">
          <p><img src={horario} alt={data.hours}/></p>
          {data.hoursTxt.map((data, i)=>(
            <p className={font.oL} key={"hours-line-" + i}>{data}<br/></p>
          ))}
        </div>
      </div>
      <iframe src="https://snazzymaps.com/embed/324888" title="Goggle maps location"/>
    </StyledSlider>
  )
}

export default Slider

const StyledSlider = styled.div`
  position: relative;
  .info-detes{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: -35vh 0 5vw;
    text-align: center;
    .flex-section{margin: 5vh 0;}
    img{height: 2em; margin-bottom: 2.5em;}
    p{line-height: 1.7em;}
  }
  iframe{
    width: 100%;
    height: 60vh;
    border: none;
  }

  #phones{
    display: block;
    position: relative;
    top: -25vh !important;
    visibility: hidden;
  }
`