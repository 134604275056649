import React from "react"
import styled from "styled-components"
import { handleClick } from "../../utils/handleClick"
import font from "../../fonts/fonts.module.scss"
import email from "../../images/contactos/Email.svg"
import horario from "../../images/contactos/Horario.svg"
import local from "../../images/contactos/Local.svg"
import phone from "../../images/contactos/Phone.svg"


const Slider = ({ data }) => {

  return (
    <StyledSlider>
      <div className="info-detes">
        <div className="flex-section">
          <p><img src={local} alt={data.address} /></p>
          {data.addressTxt.map((data, i) => (
            <p className={font.oL} key={"address-line-" + i}>{data}<br /></p>
          ))}
        </div>
        <div className="flex-section">
          <div id='phones'></div>
          <p><img src={phone} alt={data.phone} /></p>
          {data.phoneTxt.map((dataPhone, i) => (
            <>
              <a onClick={()=> handleClick("tel:" + data.phoneTxtLink[i])} key={"phone-line-" + i}>
                <p className={font.oL}>{dataPhone}<br />
                  {data.phoneTxtLink[i] === '+351 234 035 862' ?
                    <small style={{fontStyle: 'italic'}}>«Chamada para a rede fixa nacional»</small>
                    :
                    <small style={{fontStyle: 'italic'}}>«Chamada para a rede móvel nacional»</small>
                  }</p>
                  <br />
              </a>
            </>
          ))}
        </div>
        <div className="flex-section">
          <p><img src={email} alt={data.email} /></p>
          {data.emailTxt.map((dataEmail, i) => (
            <a href={"mailto:" + data.emailTxtLink[i]} key={"email-line-" + i}><p className={font.oL}>{dataEmail}<br /></p></a>
          ))}
        </div>
        <div className="flex-section">
          <p><img src={horario} alt={data.hours} /></p>
          {data.hoursTxt.map((data, i) => (
            <p className={font.oL} key={"hours-line-" + i}>{data}<br /></p>
          ))}
        </div>
      </div>
      <iframe src="https://snazzymaps.com/embed/324888" title="Goggle maps location" />
    </StyledSlider>
  )
}

export default Slider

const StyledSlider = styled.div`
  position: relative;
  margin-top: -60vh;
  .info-detes{
    display: flex;
    justify-content: center;
    margin: 18vh 0 15vw;
    height: 70%;
    text-align: center;
    .flex-section{margin: 0 2%;}
    img{height: 2em; margin-bottom: 3em;}
    p{line-height: 1.7em;}
  }
  iframe{
    width: 100%;
    height: 35vw;
    border: none;
  }

  #phones{
    display: block;
    position: relative;
    top: -25vh !important;
    visibility: hidden;
  }
`