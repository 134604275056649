import React from "react"
import styled, { css } from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import Div100vh from 'react-div-100vh'
import font from "../../fonts/fonts.module.scss"

import { Buttons } from '../buttons'

const Intro = ({ data, tall, small, btn, bmw, service, text, btnClass, actionFunction }) => {

  return (
    <StyledIntro fluid={data.background.childImageSharp.fluid} backgroundColor={`#000000`}>
      <StyledDiv100vh tall={tall ? +tall : undefined} service={service ? +service : undefined}>
        {bmw ?
          <h1 className={font.oL + " intro-title-bmw"}>
            {data.title.map((data, i) => (<span key={"introTile" + i} className={i > 0 ? font.hB : undefined}>{data}<br /></span>))}
          </h1>
          :
          <h1 className={font.oEL + " intro-title"}>
            {data.title.map((data, i) => (<span key={"introTile" + i} className={small ? i > 0 ? font.oM + " small-title" : undefined : undefined}>{data}<br /></span>))}
          </h1>
        }
        {data.subtitle && <p className={`${font.oR} intro-subtitle`}>{data.subtitle}</p>}
        {data.text && <p className={`${font.oL} intro-text`}>{data.text}</p>}
        {btn && <div className={font.oEL + ' p-relative container-btn'} >
          <Buttons text={text} actionFunction={actionFunction} btnClass={btnClass} />
        </div>}
      </StyledDiv100vh>
    </StyledIntro>
  )
}


export default Intro

const StyledIntro = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .p-relative{
    position: relative;
  }
  .container-btn{
    width: 12vw;
    margin: 0 auto 0;
    .intro-text{
      width: 100% !important;
    
    }
  }
`

const StyledDiv100vh = styled(Div100vh)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .intro-title{
    margin: 0 0 0.5em;
    text-transform: uppercase;
  }
  .intro-title-bmw{
    position: absolute;
    bottom: 8vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .intro-text{
    width: 41%;
    margin: 4em auto 8em;
  }
  .btn{
    margin-top: -5em;
    img{
      height: 3em;
    }
  }
  ${props => props.tall && css`
    .intro-title{
      margin-top: -50vh;
    }
  `}
  ${props => props.service && css`
    justify-content: flex-start;
    padding-top: 20vh;
    @media only screen and (min-height: 1200px){
      padding-top: 25vh;
    }
    padding-left: 7vw;
    text-align: left;
    .intro-text{
      width: 41%;
      margin: 4em 0 8em;
    }
  `}
`